$break-small: 400px

body
  +font-smoothing // <- _mixins.scss
  font-family: Futura
  background-color: $water-color
  margin: 0 0 0 0

svg
  width: 100%

#land-group
  fill: $land-color

#status
  position: absolute
  top: 45%
  left: 50%
  transform: translate(-50%, -50%)

  font-size: 200%
  text-align: center

  h1
    margin: 0
    padding: 0

  p
    margin: 0
    font-size: 50%

footer
  margin: 0 2em
  font-size: 75%

a
  color: $link-color
  &:visited
    color: $link-color
  &:hover
    color: lighten($link-color, 10%)

$animation-length: 16s

.group-a, .group-b
  animation: float linear $animation-length
  animation-iteration-count: infinite
  transform-origin: 50% 50%

.group-b
  animation-delay: $animation-length / -2
  transform-origin: 50% 50%

@keyframes float
  0%
    opacity: 1
    transform: translate(20px,-20px)
  50%
    opacity: 1
    transform: translate(-20px,20px)
  62%
    opacity: 1
    transform: translate(-30px,30px)
  75%
    opacity: 0
    transform: translate(-40px,40px)
  87%
    opacity: 0
    transform: translate(30px,-30px)
  100%
    opacity: 1
    transform: translate(20px,-20px)



