body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Futura;
  background-color: #71AB9B;
  margin: 0 0 0 0;
}

svg {
  width: 100%;
}

#land-group {
  fill: #eee;
}

#status {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 200%;
  text-align: center;
}
#status h1 {
  margin: 0;
  padding: 0;
}
#status p {
  margin: 0;
  font-size: 50%;
}

footer {
  margin: 0 2em;
  font-size: 75%;
}

a {
  color: #333;
}
a:visited {
  color: #333;
}
a:hover {
  color: #4d4d4d;
}

.group-a, .group-b {
  animation: float linear 16s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

.group-b {
  animation-delay: -8s;
  transform-origin: 50% 50%;
}

@keyframes float {
  0% {
    opacity: 1;
    transform: translate(20px, -20px);
  }
  50% {
    opacity: 1;
    transform: translate(-20px, 20px);
  }
  62% {
    opacity: 1;
    transform: translate(-30px, 30px);
  }
  75% {
    opacity: 0;
    transform: translate(-40px, 40px);
  }
  87% {
    opacity: 0;
    transform: translate(30px, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(20px, -20px);
  }
}
/*# sourceMappingURL=index.4b27c0f2.css.map */
